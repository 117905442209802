exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-verify-sso-js": () => import("./../../../src/pages/verify-sso.js" /* webpackChunkName: "component---src-pages-verify-sso-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-books-js": () => import("./../../../src/templates/books.js" /* webpackChunkName: "component---src-templates-books-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-resources-category-js": () => import("./../../../src/templates/resourcesCategory.js" /* webpackChunkName: "component---src-templates-resources-category-js" */),
  "component---src-templates-resources-main-js": () => import("./../../../src/templates/resourcesMain.js" /* webpackChunkName: "component---src-templates-resources-main-js" */),
  "component---src-templates-search-index-js": () => import("./../../../src/templates/search/index.js" /* webpackChunkName: "component---src-templates-search-index-js" */)
}

